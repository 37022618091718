(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('EquipFromTaskDetailController', EquipFromTaskDetailController);

    EquipFromTaskDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'EquipFromTask'];

    function EquipFromTaskDetailController($scope, $rootScope, $stateParams, previousState, entity, EquipFromTask) {
        var vm = this;

        vm.equipFromTask = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('taskServiceApp:equipFromTaskUpdate', function(event, result) {
            vm.equipFromTask = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
