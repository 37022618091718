(function () {
    'use strict';

    angular
        .module('taskServiceApp')
        .factory('PaginationUtil', PaginationUtil);

    function PaginationUtil () {

        var service = {
            parseAscending : parseAscending,
            parsePage : parsePage,
            parsePredicate : parsePredicate
        };

        return service;

        function parseAscending (sort) {
            var sortArray = sort.split(',');
            if (sortArray.length > 1){
                return sort.split(',').slice(-1)[0] === 'asc';
            } else {
                // default to true if no sort defined
                return true;
            }
        }

        // query params are strings, and need to be parsed
        function parsePage (page) {
            return parseInt(page);
        }

        // sort can be in the format `id,asc` or `id`
        function parsePredicate (sort) {
            var sortArray = sort.split(',');
            if (sortArray.length > 1){
                sortArray.pop();
            }
            return sortArray.join(',');
        }
    }

    angular
        .module('taskServiceApp')
        .directive('cusMaxNumber', ['$timeout', function ($timeout) {
            return {
                restrict: 'EA',
                require: 'ngModel',
                scope: {
                    maxNumber: '@cusMaxNumber',
                    currentPage: '@currentPage'
                },
                link: function (scope, element, attr, ctrl) {
                    ctrl.$parsers.push(function (viewValue) {
                        var maxNumber = parseInt(scope.maxNumber, 10);
                        var curNumber = scope.currentPage; //当前页数
                        var transformedInput = viewValue.replace(/[^0-9]/g, '');
                        if (transformedInput !== viewValue||parseInt(transformedInput,10)<1||parseInt(transformedInput,10)>maxNumber) {
                            ctrl.$setViewValue(curNumber);
                            ctrl.$render();
                            return curNumber;
                        }
                        return viewValue;
                    });
                }
            };
        }])
        .directive('cusPagination',[function(){
            return {
                restrict: "E",
                templateUrl: 'views/template/pagination.html',
                scope: {
                    numPerPage: "=numPerPage",
                    totalItems: "=totalItems",
                    currentPage: "=cusCurrentPage",
                    perPageSize:"=perPageSize",
                    inputCurPage:"=inputCurPage",
                    maxPages:"=maxPages",
                    pageChanged: "&pageChanged"
                },
                replace: false
            };
        }]);
})();
