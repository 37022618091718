(function () {
    'use strict';
    angular
        .module('taskServiceApp')
        .controller('TsTripPublishController', TsTripPublishController);

    TsTripPublishController.$inject = ['$timeout', '$scope', '$uibModalInstance', '$http', 'entity','$state'];

    function TsTripPublishController ($timeout, $scope, $uibModalInstance, $http, entity,$state) {

        var vm = this;
        vm.trip = entity;
        vm.clear = clear;

        vm.release = true;
        init();
        function init() {
            if (vm.trip != null) {
                if (vm.trip.type == 'Indoor') {
                    vm.trip.type = '室内版';
                } else {
                    vm.release = false;
                    vm.trip.type = '室外版';
                    $http({
                        method:'GET',
                        url: '/api/gamets/wt?tripModelId=' + vm.trip.myTripModelId,
                    }).success(function (res) {
                        console.log(res);
                        if(res != null && res.locationOpts != null && res.locationOpts !=''){
                            var locationOpts = JSON.parse(res.locationOpts);
                            if(Object.keys(locationOpts).length == 39){
                                vm.release = true;
                            }
                        }
                    });
                }
            }

        }

        vm.confirm = function () {
            $uibModalInstance.dismiss('cancel');
            window.setTimeout(function(){
                $state.go('ts-trip-publish-pay',{id: vm.trip.id},{reload:true});
            },200);
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

    }
})();






