(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('UserManagementController', UserManagementController);

    UserManagementController.$inject = ['Principal', 'User', 'UserSearch', 'ParseLinks', 'AlertService', '$state', 'pagingParams', 'paginationConstants', 'JhiLanguageService'];

    function UserManagementController(Principal, User, UserSearch, ParseLinks, AlertService, $state, pagingParams, paginationConstants, JhiLanguageService) {
        var vm = this;

        vm.authorities = ['ROLE_USER', 'ROLE_USERDESIGN', 'ROLE_OPERATION', 'ROLE_ADMIN', 'ROLE_SUPER'];
        vm.currentAccount = null;
        vm.languages = null;
        vm.loadAll = loadAll;
        vm.setActive = setActive;
        vm.users = [];
        vm.page = 1;
        vm.totalItems = null;
        vm.clear = clear;
        vm.links = null;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.transition = transition;

        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;

        vm.clear = clear;
        vm.search = search;

        vm.loadAll();

        JhiLanguageService.getAll().then(function (languages) {
            vm.languages = languages;
        });
        Principal.identity().then(function(account) {
            vm.currentAccount = account;
        });

        function setActive (user, isActivated) {
            user.activated = isActivated;
            User.update(user, function () {
                vm.loadAll();
                vm.clear();
            });
        }

        /*function loadAll () {
         User.query({
         page: pagingParams.page - 1,
         size: vm.itemsPerPage,
         sort: sort()
         }, onSuccess, onError);
         }*/

        function loadAll () {
            if (pagingParams.search) {
                UserSearch.query({
                    query: pagingParams.search,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                User.query({
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                for (var i in data) {
                    if (data[i]['login'] === 'anonymoususer') {
                        data.splice(i, 1);
                    }
                }
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.users = data;
                vm.page = pagingParams.page;
                // console.log("data")
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        /*function onSuccess(data, headers) {
         //hide anonymous user from user management: it's a required user for Spring Security
         for (var i in data) {
         if (data[i]['login'] === 'anonymoususer') {
         data.splice(i, 1);
         }
         }
         vm.links = ParseLinks.parse(headers('link'));
         vm.totalItems = headers('X-Total-Count');
         vm.queryCount = vm.totalItems;
         vm.page = pagingParams.page;
         vm.users = data;
         }

         function onError(error) {
         AlertService.error(error.data.message);
         }*/


        function search (searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            //pagingParams.search=searchQuery;
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear () {
            vm.user = {
                id: null, login: null, firstName: null, lastName: null, email: null,
                activated: null, langKey: null, createdBy: null, createdDate: null,
                lastModifiedBy: null, lastModifiedDate: null, resetDate: null,
                resetKey: null, authorities: null
            };
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }

        /*function sort () {
         var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
         if (vm.predicate !== 'id') {
         result.push('id');
         }
         return result;
         }*/

        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

    }
})();
