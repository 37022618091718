(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('VersionDescDeleteController',VersionDescDeleteController);

    VersionDescDeleteController.$inject = ['$uibModalInstance', 'entity', 'VersionDesc'];

    function VersionDescDeleteController($uibModalInstance, entity, VersionDesc) {
        var vm = this;

        vm.versionDesc = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            VersionDesc.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
