(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .factory('TeamActivitySearch', TeamActivitySearch);

    TeamActivitySearch.$inject = ['$resource'];

    function TeamActivitySearch($resource) {
        var resourceUrl =  'api/_search/team-activities/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
