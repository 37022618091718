(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .factory('TsTripSearch', TsTripSearch);

    TsTripSearch.$inject = ['$resource'];

    function TsTripSearch($resource) {
        var resourceUrl =  'api/gamets/_search/trips/';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();

