(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('LocationDialogController', LocationDialogController);

    LocationDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Location','GdMapModalService'];

    function LocationDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Location, GdMapModalService) {
        var vm = this;
        vm.IMAGE_SERVER = IMAGE_SERVER;
        vm.location = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.show = show;
        vm.point = {};

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            // if (vm.location.laititude<-90.000000 || vm.location.laititude>90.000000){
            //     alert("坐标纬度不合法");
            //     vm.isSaving = false;
            //     return;
            // }
            // if (vm.location.longtitude<-180.000000 || vm.location.longtitude>180.000000){
            //     alert("坐标经度不合法");
            //     vm.isSaving = false;
            //     return;
            // }
            var laititude = vm.location.laititude;
            if (typeof laititude!=='undefined' && laititude!=null){
                laititude = Math.abs(laititude);
                if (laititude>90.000000){
                    alert("坐标纬度不合法");
                    vm.isSaving = false;
                    return;
                }
            }
            var longitude = vm.location.longitude;
            if (typeof longitude!=='undefined' && longitude!=null){
                longitude = Math.abs(longitude);
                if (longitude>180.000000){
                    alert("坐标经度不合法");
                    vm.isSaving = false;
                    return;
                }
            }
            if (vm.location.id !== null) {
                Location.update(vm.location, onSaveSuccess, onSaveError);
            } else {
                Location.save(vm.location, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('taskServiceApp:locationUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.createDate = false;
        vm.datePickerOpenStatus.updateDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
        function show () {
            //[高德地图] (https://lbs.amap.com/)
            GdMapModalService.showModal(vm.location).result.then(function (data) {
                vm.location.laititude = data.point.lat;
                vm.location.longtitude = data.point.lng;
                vm.location.city = data.city;
            });
        }
    }
})();
