(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('TsMyPayController', TsMyPayController);

    TsMyPayController.$inject = ['$cookies','$scope', '$rootScope','$http', '$state', '$stateParams','getTsOrder','$interval', 'previousState', 'entity'];

    function TsMyPayController($cookies,$scope, $rootScope,$http, $state, $stateParams,getTsOrder,$interval, previousState, entity) {
        var vm = this;
        vm.order = null;
        vm.eAccount = entity;
        vm.previousState = previousState.name;
        vm.itemsPerPage = 250;
        vm.toError = toError;
        vm.toSuccess = toSuccess;

        if ($stateParams.orderId!=null){
            $http({
                method: 'GET',
                url: '/api/gamets/order/' + $stateParams.orderId
            }).success(function (data) {
                vm.order = data;
            })
        }

        //每3s轮询一次,轮循45s(15次)后结束
        var stop = $interval(function() {
            $http({
                method: 'GET',
                url: '/api/gamets/order/status',
                params: {out_trade_no:$stateParams.orderId}
            }).success(function (data) {
                vm.payStatus = data;
                if (data.errorCode===0){
                    console.log("支付成功");
                    $interval.cancel(stop);
                    if ($cookies.get("ts-trip") != null){
                        $http({
                            method:'POST',
                            url:'/api/gamets/trip/publish?tripId=' + $cookies.get("ts-trip")
                        }).success(function () {
                            toSuccess();
                        }).error(function(){
                            onSaveError();
                        });
                    }
                }
            }).error(function(err){
                console.log(err);
                $interval.cancel(stop);
            });
        },3000,15);
        stop.then(function () {
            $('#myModal1').modal('show');
        });
        //离开页面后停止轮询
        $scope.$on('$destroy',function(){
            $interval.cancel(stop);
        });

        function toError() {
            $('#myModal1').modal('hide');
            window.setTimeout(function () {
                $interval.cancel(stop);
                $state.go("ts-my-pay-error");
            },300)
        }
        function toSuccess() {
            $cookies.remove("ts-trip");
            $('#myModal1').modal('hide');
            $interval.cancel(stop);
            //判断是否支付成功，成功则跳到成功页面，失败则跳到失败页面
            if (vm.payStatus.errorCode===0){
                window.setTimeout(function () {
                    $state.go("ts-my-pay-success");
                },300)
            } else {
                window.setTimeout(function () {
                    $state.go("ts-my-pay-wait");
                },300)
            }
        }

        function onSaveError () {
        }

    }
})();


(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('TsMyPaySuccessController', TsMyPaySuccessController);

    TsMyPaySuccessController.$inject = ['$interval','$state'];

    function TsMyPaySuccessController($interval,$state) {

        var vm = this;
        vm.second = 5;
        timeOut();

        function timeOut() {
            var stop = $interval(function () {
                vm.second = vm.second-1;
            },1000,5);
            stop.then(function () {
                $interval.cancel(stop);
                $state.go('ts-trips', {}, {reload: true});
            })
        }

    }
})();


(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('TsMyPayErrorController', TsMyPayErrorController);

    TsMyPayErrorController.$inject = ['$state','$cookies'];

    function TsMyPayErrorController($state,$cookies) {
        var vm = this;

        vm.back = back;
        function back() {
            $state.go("ts-trip-publish-pay",{id: $cookies.get("ts-trip")}, null);
        }

    }
})();


(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('TsMyPayWaitController', TsMyPayWaitController);

    TsMyPayWaitController.$inject = ['$state'];

    function TsMyPayWaitController($state) {
        var vm = this;

        vm.back = back;
        function back() {
            $state.go("ts-trips");
        }

    }
})();
