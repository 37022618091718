(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('EAccountMyPaySuccessController', EAccountMyPaySuccessController);

    EAccountMyPaySuccessController.$inject = ['$interval','$state'];

    function EAccountMyPaySuccessController($interval,$state) {

        var vm = this;
        vm.second = 5;
        timeOut();

        function timeOut() {
            var stop = $interval(function () {
                vm.second = vm.second-1;
            },1000,5);
            stop.then(function () {
                $interval.cancel(stop);
                $state.go('e-account-my');
            })
        }

    }
})();
