(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('BaseTaskDeleteController',BaseTaskDeleteController);

    BaseTaskDeleteController.$inject = ['$uibModalInstance', 'entity', 'BaseTask','$http'];

    function BaseTaskDeleteController($uibModalInstance, entity, BaseTask,$http) {
        var vm = this;

        vm.AR_SERVER = AR_SERVER;
        vm.baseTask = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        var params = {pks:[vm.baseTask.id]};

        function deleteAREvent() {
            //删除AR任务时同时删除此AR任务创建的事件
            // if (vm.baseTask.type === 'AR' && vm.baseTask.newArModelId!=null){
            //     $http({
            //         method: 'post',
            //         url: vm.AR_SERVER+'/api/task/delete',
            //         data: params
            //     }).then(function (res) {
            //         console.log(res);
            //     },function (err) {
            //         console.log(err);
            //     })
            // }
        }

        function confirmDelete (id) {
            BaseTask.delete({id: id},
                function (res) {
                    // console.log(res);
                    deleteAREvent();
                    $uibModalInstance.close(true);
                },function (err) {
                    console.log(err);
                });
        }
    }
})();
