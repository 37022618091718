(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('confirm-join', {
            parent: 'admin',
            url: '/confirm-join/{eid}/{eName}/{uid}/{uName}',
            data: {
                authorities: []
            },
            views: {
                'navbar@': {
                }
            },
            onEnter: ['$stateParams', '$uibModal', function($stateParams, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/account/join-eaccount/confirm-join.html',
                    controller: 'ConfirmJoinController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                        entity: null
                    }
                }).result.then(function() {
                }, function() {
                });
            }]
        })

    }
})();
