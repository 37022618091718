(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('TeamEquipDetailController', TeamEquipDetailController);

    TeamEquipDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'TeamEquip'];

    function TeamEquipDetailController($scope, $rootScope, $stateParams, previousState, entity, TeamEquip) {
        var vm = this;

        vm.teamEquip = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('taskServiceApp:teamEquipUpdate', function(event, result) {
            vm.teamEquip = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
