(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('AvatarDialogController', AvatarDialogController);

    AvatarDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Avatar', 'Upload', 'Ahdin', 'Principal'];

    function AvatarDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Avatar, Upload, Ahdin, Principal) {
        var vm = this;

        vm.avatar = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.IMAGE_SERVER = IMAGE_SERVER;
        vm.onFileSelect = onFileSelect;
        vm.showCropped = true;
        vm.cropper = 'default';

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        getAccount();
        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
            })
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        vm.croppedImg = croppedImg;
        function croppedImg(img) {
            // console.log(img);
            img = getBlobBydataURL(img);
            img.name = new Date().getTime() + '.png';
            // console.log(img);
            onFileSelect(img,'coverImg');
            vm.showCropped = false;
        }
        //将base64转换成图片文件格式
        function getBlobBydataURL(dataURI){
            var binary = atob(dataURI.split(',')[1]);
            var array = [];
            for(var i = 0; i < binary.length; i++) {
                array.push(binary.charCodeAt(i));
            }
            return new Blob([new Uint8Array(array)], {type:'image/png' });
        }

        vm.removeCoppedImg = removeCoppedImg;
        function removeCoppedImg() {
            if (vm.avatar.avatar != null) {
                vm.avatar.avatar = null;
            }
            $scope.sourceImage = null;
            $scope.croppedImage = null;
            vm.showCropped = true;
        }

        function save () {
            vm.isSaving = true;

            if (vm.avatar.avatar !== null){
                var image = document.getElementById("file");
                if (image != null){
                    var height = image.height;
                    var width = image.width;
                    var bi = width / height;
                    if (bi<0.8 || bi>1.2) {
                        alert('上传图片比例1:1，且大小不得大于500kb');
                        if (vm.avatar.avatar != null){
                            vm.avatar.avatar = null;
                        }
                        vm.isSaving = false;
                        return;
                    }
                }
            }else {
                alert("头像不能为空！");
                vm.isSaving = false;
                return;
            }

            //设置默认头像类型为旁白
            vm.avatar.type = '旁白';

            // var date = new Date();

            if (vm.avatar.id !== null) {
                // vm.avatar.updateDate = date;
                // vm.avatar.updateBy = vm.account.firstName;
                Avatar.update(vm.avatar, onSaveSuccess, onSaveError);
            } else {
                // vm.avatar.createDate = date;
                // vm.avatar.createBy = vm.account.firstName;
                Avatar.save(vm.avatar, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('taskServiceApp:avatarUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.createDate = false;
        vm.datePickerOpenStatus.updateDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function onFileSelect (uploadFile){

            if (vm.avatar.type=='旁白'){
                var uploadImageFile = function(compressedBlob) {

                    var imgSize = compressedBlob.size/1024;
                    // console.log(imgSize);
                    if (imgSize>500){
                        alert("图片大小超过500kb");
                        return;
                    }

                    Upload.upload({

                        url: '/api/postfile',
                        fields: {},
                        data: {type:"ASIDE"},
                        file: compressedBlob,
                        method: 'POST'

                    }).progress(function (evt) {

                        var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                        console.log('progress: ' + progressPercentage + '% ');

                    }).success(function (data, status, headers, config) {

                        // var imgSize = compressedBlob.size/1024;
                        // // console.log(imgSize);
                        // if (imgSize>500){
                        //     alert("图片大小超过500kb");
                        //     return;
                        // }
                        //update the url
                        vm.avatar.avatar = data.file;

                    }).error(function (data, status, headers, config) {

                        console.log('error status: ' + status);
                    });
                };
            }else {
                var uploadImageFile = function(compressedBlob) {

                    var imgSize = compressedBlob.size/1024;
                    // console.log(imgSize);
                    if (imgSize>500){
                        alert("图片大小超过500kb");
                        return;
                    }

                    Upload.upload({

                        url: '/api/postfile',
                        fields: {},
                        data: {type:"NPC"},
                        file: compressedBlob,
                        method: 'POST'

                    }).progress(function (evt) {

                        var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                        console.log('progress: ' + progressPercentage + '% ');

                    }).success(function (data, status, headers, config) {

                        // var imgSize = compressedBlob.size/1024;
                        // // console.log(imgSize);
                        // if (imgSize>500){
                        //     alert("图片大小超过500kb");
                        //     return;
                        // }
                        //update the url
                        vm.avatar.avatar = data.file;

                    }).error(function (data, status, headers, config) {

                        console.log('error status: ' + status);
                    });
                };
            }

            //TODO gif no compress
            if (uploadFile != null) {
                // Ahdin.compress({
                //     sourceFile: uploadFile,
                //     maxWidth: 1280,
                //     maxHeight:1000,
                //     quality: 0.8
                // }).then(function(compressedBlob) {
                //     console.log('compressed image by ahdin.');
                    uploadImageFile(uploadFile);
                // });
            }


        }
    }
})();
