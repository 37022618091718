(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('ExportRechargeController', ExportRechargeController);

    ExportRechargeController.$inject = ['$timeout', '$uibModalInstance','$filter'];

    function ExportRechargeController($timeout, $uibModalInstance,$filter) {
        var vm = this;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;

        vm.datePickerOpenStatus.startTime = false;
        vm.datePickerOpenStatus.endTime = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }


        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
    }

    angular
        .module('taskServiceApp')
        .controller('ExportConsumeController', ExportConsumeController);

    ExportConsumeController.$inject = ['$timeout', '$uibModalInstance','$filter'];

    function ExportConsumeController($timeout, $uibModalInstance,$filter) {
        var vm = this;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;

        vm.datePickerOpenStatus.startTime = false;
        vm.datePickerOpenStatus.endTime = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }


        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();
