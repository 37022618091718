/**
 * Created by harlanchang on 2018/3/16.
 */
(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('GDMapController', GDMapController);

    GDMapController.$inject = ['$scope', '$uibModalInstance', 'point'];

    function GDMapController ($scope, $uibModalInstance, point) {
        var data={};
        var geocoder;
        $scope.city = null;

        $scope.init = function () {
            $scope.point = point
        }

        $scope.getCity = function (e) {
            AMap.service('AMap.Geocoder',function(){//回调函数
                //实例化Geocoder
                geocoder = new AMap.Geocoder({
                    city: "all"//城市，默认：“全国”
                });
                //TODO: 使用geocoder 对象完成相关功能
                geocoder.getAddress(e.lnglat,function(status,result){
                    if(status=='complete'){
                        $scope.city = result.regeocode.addressComponent.city;
                        if ($scope.city.length == 0){
                            $scope.city = result.regeocode.addressComponent.province;
                        }
                        // console.log($scope.city);
                    }else{
                        console.log('无法获取地址')
                    }
                })
            })
        }

        $scope.$on('map-click', function (event, e) {
            $scope.point = {
                lat: e.lnglat.getLat(),
                lng: e.lnglat.getLng()
            }
            $scope.getCity(e);
            // console.log($scope.city);
            //$scope.$apply()
        })
        $scope.$on('update-center', function (event, center) {
            $scope.point = {
                lat: center[1],
                lng: center[0]
            }
            //$scope.$apply()
        })

        $scope.search = function (q) {
            AMap.service(["AMap.PlaceSearch"], function () {
                var placeSearch = new AMap.PlaceSearch({ //构造地点查询类
                    pageSize: 5,
                    pageIndex: 1,
                    city: "021", //城市
                    map: self.map,
                    panel: "panel"
                });

                //关键字查询
                placeSearch.search(q, function (status, result) {
                    //TODO : 按照自己需求处理查询结果
                    var poiList = result.poiList

                    if (poiList.count > 0) {
                        var p = poiList.pois[0]
                        $scope.$broadcast('setCenter', [p.location.lng, p.location.lat])

                    }
                })
            })
        }

        $scope.ok = function () {
            data.point=$scope.point;
            data.city=$scope.city;
            $uibModalInstance.close(data);
            // console.log(data);
        }

        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();
