(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('TeamActivityDetailController', TeamActivityDetailController);

    TeamActivityDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'TeamActivity', 'TeamActivitySummary'];

    function TeamActivityDetailController($scope, $rootScope, $stateParams, previousState, entity, TeamActivity, TeamActivitySummary) {
        var vm = this;

        vm.teamActivity = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('taskServiceApp:teamActivityUpdate', function(event, result) {
            vm.teamActivity = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
