(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('TsTripCopyController',TsTripCopyController);

    TsTripCopyController.$inject = ['$uibModalInstance', '$http','$state', '$stateParams'];

    function TsTripCopyController($uibModalInstance, $http, $state, $stateParams) {
        var vm = this;

        vm.clear = clear;
        vm.confirmCopy = confirmCopy;
        vm.cannotOpt = false;
        if($stateParams.id != null && $stateParams.id != ''){
            vm.tripId = $stateParams.id;
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmCopy () {
            vm.cannotOpt = true;
            vm.clear();
            $http({
                method: 'GET',
                url: '/api/gamets/trip/copy/' + vm.tripId
            }).then(function () {
                $state.go('ts-trips',null ,{reload:true});
                $uibModalInstance.dismiss('cancel');
            },function (err) {
                console.log(err);
            });
        }
    }
})();
