(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('TsTripRankController', TsTripRankController);

    TsTripRankController.$inject = ['$location','$scope', '$rootScope','$state', '$stateParams','$http', 'previousState', 'entity', 'Trip', 'Principal' ,'TsTrip'];

    function TsTripRankController($location,$scope, $rootScope, $state, $stateParams,$http, previousState, entity, Trip, Principal, TsTrip) {
        var vm = this;
        vm.tripRanks = entity;
        vm.previousState = previousState.name;

        getAccount();
        //获取当前登录的账号
        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                if (vm.account !== null){
                    var author = vm.account.authorities;
                    if ($.inArray("ROLE_SUPER",author)!==-1){
                        vm.auth = "Super";
                    }
                }
            });
        }

        TsTrip.get({id:$stateParams.id},function (res) {
            if (res != null){
                vm.trip = res;
            }
        },function (err) {
            console.log(err);
        });



    }


})();
