(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .factory('TaskLocationTeamSearch', TaskLocationTeamSearch);

    TaskLocationTeamSearch.$inject = ['$resource'];

    function TaskLocationTeamSearch($resource) {
        var resourceUrl =  'api/_search/task-location-teams/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
