(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .factory('TripSearch', TripSearch);

    TripSearch.$inject = ['$resource'];

    function TripSearch($resource) {
        var resourceUrl =  'api/_search/trips/';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .factory('TripSearchOfficial', TripSearchOfficial);

    TripSearchOfficial.$inject = ['$resource'];

    function TripSearchOfficial($resource) {
        var resourceUrl =  'api/_search/tripsOfficial/';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .factory('TripTodaySearch', TripTodaySearch);

    TripTodaySearch.$inject = ['$resource'];

    function TripTodaySearch($resource) {
        var resourceUrl =  'api/_search/TripTodaySearch/';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .factory('TripPublishSearch', TripPublishSearch);

    TripPublishSearch.$inject = ['$resource'];

    function TripPublishSearch($resource) {
        var resourceUrl =  'api/_search/publish/trips/';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .factory('TripSearchByStatus', TripSearchByStatus);

    TripSearchByStatus.$inject = ['$resource'];

    function TripSearchByStatus($resource) {
        var resourceUrl =  'api/_search/status/trips/';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
