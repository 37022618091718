(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('UserManagementDetailController', UserManagementDetailController);

    UserManagementDetailController.$inject = ['$stateParams', 'User','$http', 'Trip','Principal'];

    function UserManagementDetailController ($stateParams, User, $http, Trip,Principal) {
        var vm = this;

        if ($stateParams.login!=null){
            vm.load = load;
            // vm.user = {};
            vm.load($stateParams.login);
        }
        // showTeams($stateParams.id);
        // showTrips($stateParams.id);
        function load (login) {
            User.get({login: login}, function(result) {
                vm.user = result;
                showTeams($stateParams.id);
                showTrips($stateParams.id);
            });
        }
        if ($stateParams.id!=null){
            getEAccount($stateParams.id);
        }
        //获取要查看用户的企业信息
        function getEAccount(userId) {
            $http({
                url: 'api/getEAccountByUserId?userId='+userId,
                method: 'GET'
            }).success(function (data) {
                // console.log(data);
                vm.account = data;
            }).error(function (err) {
                console.log(err);
            })
        }

        function showTeams(id) {
            $http({
                method: 'GET',
                url: '/api/teams/user/'+id
            }).success(function (data) {
                if (data!=null){
                    vm.user.teams=data;
                }
                // console.log(data);
            }).error(function(err){
                console.log(err);
            });
        }
        function showTrips(userId) {
            $http({
                method: 'GET',
                url: '/api/trips/getTripsByUser/'+userId
            }).success(function (data) {
                if (data!=null){
                    vm.user.trips=data;
                }
                // console.log(data);
            }).error(function(err){
                console.log(err);
            });
        }

    }
})();
