(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('TeamTaskProgressDetailController', TeamTaskProgressDetailController);

    TeamTaskProgressDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'TeamTaskProgress'];

    function TeamTaskProgressDetailController($scope, $rootScope, $stateParams, previousState, entity, TeamTaskProgress) {
        var vm = this;

        vm.teamTaskProgress = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('taskServiceApp:teamTaskProgressUpdate', function(event, result) {
            vm.teamTaskProgress = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
