(function() {
    'use strict';
    angular
        .module('taskServiceApp')
        .factory('Team', Team);

    Team.$inject = ['$resource', 'DateUtils'];

    function Team ($resource, DateUtils) {
        var resourceUrl =  'api/teams/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true, params:{size:10000}},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.createDate = DateUtils.convertDateTimeFromServer(data.createDate);
                        data.updateDate = DateUtils.convertDateTimeFromServer(data.updateDate);
                    }
                    console.log(data);
                    data.oldUser=data.hasMembers;
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();
