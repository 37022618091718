(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('TeamEquipDeleteController',TeamEquipDeleteController);

    TeamEquipDeleteController.$inject = ['$uibModalInstance', 'entity', 'TeamEquip'];

    function TeamEquipDeleteController($uibModalInstance, entity, TeamEquip) {
        var vm = this;

        vm.teamEquip = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            TeamEquip.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
