(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('ArModelNewDialogController', ArModelNewDialogController);

    ArModelNewDialogController.$inject = ['$timeout', '$scope','$http','getArModel','Upload', '$stateParams', '$uibModalInstance', 'entity', 'ArModelNew'];

    function ArModelNewDialogController ($timeout, $scope,$http,getArModel,Upload, $stateParams, $uibModalInstance, entity, ArModelNew) {
        var vm = this;

        vm.arModelNew = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.IMAGE_SERVER = IMAGE_SERVER;
        vm.AR_SERVER = AR_SERVER;
        vm.onFileSelect = onFileSelect;
        var imgLimit = 500;//上传图片限制，单位kb
        var otherLimit = 5000;//上传视频限制，单位kb
        var allData = {};
        vm.other = new Array(2);

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        //默认打开页面加载所需展示项
        showReward();
        function showReward() {
            // changeType();
            if (vm.arModelNew.armodeltype!=null){
                var arModelType = vm.arModelNew.armodeltype.toLocaleLowerCase();
                $http({
                    method: 'GET',
                    url: vm.AR_SERVER+'/api/template/armodel?type='+arModelType
                }).success(function (data) {
                    // console.log(data);
                    vm.getData = data;
                }).error(function (err) {
                    console.log(err);
                })
            }

            if (vm.arModelNew.reward!=null){
                var reward = angular.fromJson(vm.arModelNew.reward);
                // console.log(reward);
                vm.scan_image = reward.scan_image;
                vm.times = reward.times;
                if (reward.reward!=null){
                    vm.reward = reward.reward.rewardType;
                    console.log(vm.reward);
                    vm.scrolls_desc = reward.reward.scrolls_desc;
                    vm.scrolls_image = reward.reward.scrolls_image;
                    vm.scrolls_style = reward.reward.scrolls_style;
                }
                if (/\.(jpg|jpeg|png|bmp)$/.test(reward.custom_rimg)){
                    vm.custom_rimg = reward.custom_rimg;
                }else if (/\.(mp3)$/.test(reward.custom_rmp3)){
                    vm.custom_rmp3 = reward.custom_rmp3;
                }else if (/\.(mp4)$/.test(reward.custom_rmp4)) {
                    vm.custom_rmp4 = reward.custom_rmp4;
                }
                // vm.scissor_speed = reward.scissor_speed;
                vm.password = reward.password;
                vm.obj = reward.obj;
                vm.pos_x = reward.pos_x;
                vm.pos_y = reward.pos_y;
                vm.pos_z = reward.pos_z;
                vm.scale = reward.scale;
                vm.other = reward.other;
                vm.audio = reward.audio;
                vm.phone = reward.phone;
            }
        }
        vm.changeType = changeType;
        function changeType() {
            // console.log(vm.arModelNew.armodeltype);
            // vm.allData = getArModel.get({type:arModelType});
            // console.log(vm.allData);
            if (vm.arModelNew.armodeltype!=null){
                var arModelType = vm.arModelNew.armodeltype.toLocaleLowerCase();
                $http({
                    method: 'GET',
                    url: vm.AR_SERVER+'/api/template/armodel?type='+arModelType
                }).success(function (data) {
                    console.log(data);
                    vm.getData = data;
                    vm.reward={};
                }).error(function (err) {
                    console.log(err);
                })
            }
        }

        function save () {
            vm.isSaving = true;

            //将各种AR类型录入的必填字段保存为json数据
            //怪物七剑和炸弹引线拆除不需要扫描图片
            if (vm.arModelNew.armodeltype!=='MONSTER'&&vm.arModelNew.armodeltype!=='BOMB_LEAD'){
                if (vm.scan_image!=null){allData.scan_image = vm.scan_image;}else {allData.scan_image = ""}
            }
            //炸弹密码拆除录入项
            if (vm.arModelNew.armodeltype==='BOMB_CODE'){
                if (vm.times!=null){allData.times=vm.times;}else {allData.times=""}
            }
            //密码箱和宝箱录入奖赏类型
            if (vm.arModelNew.armodeltype==='CODE_CASE'||vm.arModelNew.armodeltype==='TREASURE_BOX') {
                allData.reward = {};
                if (vm.reward != null) {allData.reward.rewardType = vm.reward;} else {allData.reward.rewardType = ""}
            }
            //羊皮卷三种类型保存
            if ((vm.arModelNew.armodeltype==='CODE_CASE'||vm.arModelNew.armodeltype==='TREASURE_BOX')&&vm.reward==='scrolls'){
                allData.reward = {rewardType:'scrolls'};
                if (vm.scrolls_desc!=null){allData.reward.scrolls_desc = vm.scrolls_desc;}else {allData.reward.scrolls_desc = ""}
                if (vm.scrolls_image!=null){allData.reward.scrolls_image = vm.scrolls_image;}else {allData.reward.scrolls_image = ""}
                if (vm.scrolls_style!=null){allData.reward.scrolls_style = vm.scrolls_style;}else {allData.reward.scrolls_style = ""}
            }
            //怪物七剑简化版保存(只写了编辑页面，未写保存及默认展示)
            if (vm.arModelNew.armodeltype==='MONSTER') {
                if (vm.appear_frame!=null){allData.appear_frame = vm.appear_frame;}else {allData.appear_frame = ""}
                if (vm.hit_point!=null){allData.hit_point = vm.hit_point;}else {allData.hit_point = ""}
            }
            //平面资源
            if (vm.arModelNew.armodeltype==='CUSTOM_RESOURCE') {
                if (vm.custom_rimg!=null){allData.custom_rimg = vm.custom_rimg}
                if (vm.custom_rmp3!=null){allData.custom_rmp3 = vm.custom_rmp3}
                if (vm.custom_rmp4!=null){allData.custom_rmp4 = vm.custom_rmp4}
            }
            //炸弹-引线拆除
            if (vm.arModelNew.armodeltype==='BOMB_LEAD') {
                // if (vm.scissor_speed!=null){allData.scissor_speed = vm.scissor_speed}else {allData.scissor_speed = ""}
                if (vm.password!=null){allData.password = vm.password}else {allData.password = ""}
            }
            //自定义3D模型
            if (vm.arModelNew.armodeltype==='CUSTOM_3D') {
                if (vm.obj!=null){allData.obj = vm.obj}else {allData.obj = ""}
                if (vm.pos_x!=null){allData.pos_x = vm.pos_x}else {allData.pos_x = ""}
                if (vm.pos_y!=null){allData.pos_y = vm.pos_y}else {allData.pos_y = ""}
                if (vm.pos_z!=null){allData.pos_z = vm.pos_z}else {allData.pos_z = ""}
                if (vm.scale!=null){allData.scale = vm.scale}else {allData.scale = ""}
                // if (vm.other!=null){allData.other = vm.other}else {allData.other = ""}
                allData.other = vm.other;
            }
            //电话-平面拨号
            if (vm.arModelNew.armodeltype==='PHONE'){
                if (vm.audio!=null){allData.audio = vm.audio}else {allData.audio = ""}
                if (vm.phone!=null){allData.phone = vm.phone}else {allData.phone = ""}
            }

            vm.arModelNew.reward = angular.toJson(allData);
            // console.log(vm.arModelNew.reward);

            if (vm.arModelNew.id !== null) {
                ArModelNew.update(vm.arModelNew, onSaveSuccess, onSaveError);
            } else {
                ArModelNew.save(vm.arModelNew, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('taskServiceApp:arModelNewUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.createdDate = false;
        vm.datePickerOpenStatus.lastModifiedDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

        vm.removeImg = removeImg;
        function removeImg() {
            if (vm.scan_image != null){
                vm.scan_image = null;
            }
        }
        vm.removeScrolls_image = removeScrolls_image;
        function removeScrolls_image() {
            if (vm.scrolls_image != null){
                vm.scrolls_image = null;
            }
        }
        vm.removeAppear_frame = removeAppear_frame;
        function removeAppear_frame() {
            if (vm.appear_frame != null){
                vm.appear_frame = null;
            }
        }
        vm.removeHit_frame = removeHit_frame;
        function removeHit_frame() {
            if (vm.hit_frame != null){
                vm.hit_frame = null;
            }
        }
        vm.removeIdle_frame = removeIdle_frame;
        function removeIdle_frame() {
            if (vm.idle_frame != null){
                vm.idle_frame = null;
            }
        }
        vm.removeFrame_config = removeFrame_config;
        function removeFrame_config() {
            if (vm.frame_config != null){
                vm.frame_config = null;
            }
        }
        vm.removeDeath_frame = removeDeath_frame;
        function removeDeath_frame() {
            if (vm.death_frame != null){
                vm.death_frame = null;
            }
        }
        vm.removeCustom_r = removeCustom_r;
        function removeCustom_r() {
            if (vm.custom_rimg!=null){vm.custom_rimg=null}
            else if (vm.custom_rmp3!=null){vm.custom_rmp3=null}
            else if (vm.custom_rmp4!=null){vm.custom_rmp4=null}
        }
        vm.removeobj = removeobj;
        function removeobj() {
            if (vm.obj != null){
                vm.obj=null;
            }
        }
        vm.removeOther = removeOther;
        function removeOther() {
            if (vm.other != null){
                vm.other=null;
            }
        }
        vm.removeAudio = removeAudio;
        function removeAudio() {
            if (vm.audio != null){
                vm.audio=null;
            }
        }

        //上传图片格式（jpg|jpeg|png|bmp），视频格式（mp3|mp4）
        function checkPic(file,imgSize) {
            var isCanCheck = true;
            // console.log(file);
            if (/\.(jpg|jpeg|png|bmp)$/.test(file)){
                // console.log(imgSize);
                if (imgSize>imgLimit){
                    alert("图片大小超过"+imgLimit+"kb");
                    isCanCheck = false;
                }
            }else if(/\.(mp3|mp4)$/.test(file)){
                if (imgSize>otherLimit){
                    alert("音视频大小超过"+otherLimit+"kb");
                    isCanCheck = false;
                }
            }else if (/\.(obj)$/.test(file)){
                if (imgSize>otherLimit){
                    alert("obj文件大小超过"+otherLimit+"kb")
                }
            }else{
                alert("文件格式不正确，请重新上传！！");
                isCanCheck = false;
            }
            return isCanCheck;
        }
        function onFileSelect (uploadFile, name){
            if (uploadFile!=null){
                uploadImageFile(uploadFile);
            }
            function uploadImageFile(compressedBlob) {
                var file = compressedBlob.name;
                var imgSize = compressedBlob.size/1024;
                var boolean = checkPic(file,imgSize);
                if (boolean){
                    Upload.upload({
                        url: '/api/postfile',
                        fields: {},
                        data: {type:"AR_MODEL"},
                        file: compressedBlob,
                        method: 'POST'
                    }).progress(function (evt) {
                        var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                        console.log('progress: ' + progressPercentage + '% ');
                    }).success(function (data, status, headers, config) {
                        if (name == 'img'){
                            vm.scan_image = data.file;
                        }else if (name == 'scrolls_image'){
                            vm.scrolls_image = data.file;
                        }else if (name == 'appear_frame'){
                            vm.appear_frame = data.file;
                        }else if (name == 'hit_frame'){
                            vm.hit_frame = data.file;
                        }else if (name == 'idle_frame'){
                            vm.idle_frame = data.file;
                        }else if (name == 'frame_config'){
                            vm.frame_config = data.file;
                        }else if (name == 'death_frame'){
                            vm.death_frame = data.file;
                        }else if (name == 'custom_r'){
                            if (/\.(jpg|jpeg|png|bmp)$/.test(data.file)){
                                vm.custom_rimg = data.file;
                            }else if (/\.(mp3)$/.test(data.file)){
                                vm.custom_rmp3 = data.file;
                            }else if (/\.(mp4)$/.test(data.file)){
                                vm.custom_rmp4 = data.file;
                            }
                        }else if (name == 'obj'){
                            vm.obj = data.file;
                        }else if (name == 'other'){
                            vm.other[0] = data.file;
                        }else if (name == 'other2'){
                            vm.other[1] = data.file;
                        }else if (name == 'audio'){
                            if (/\.(mp3)$/.test(data.file)){
                                vm.audio = data.file;
                            }else {
                                alert("请上传mp3格式的音频文件");
                            }
                        }
                    }).error(function (data, status, headers, config) {
                        console.log('error status: ' + status);
                    });
                }
            }
        }
    }
})();
