(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('CheckPassEAccountController', CheckPassEAccountController);

    CheckPassEAccountController.$inject = ['$state'];

    function CheckPassEAccountController($state) {
        var vm = this;
    }
})();
