(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('TeamLocationProgressDetailController', TeamLocationProgressDetailController);

    TeamLocationProgressDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'TeamLocationProgress'];

    function TeamLocationProgressDetailController($scope, $rootScope, $stateParams, previousState, entity, TeamLocationProgress) {
        var vm = this;

        vm.teamLocationProgress = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('taskServiceApp:teamLocationProgressUpdate', function(event, result) {
            vm.teamLocationProgress = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
