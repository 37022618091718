/**
 * Created by lichen on 2016/10/5.
 */
(function (angular) {

    var app = angular.module('taskServiceApp');

    app.factory('GdMapModalService', ['$uibModal', function ($uibModal) {
        var service = {};

        service.showModal = function (data) {

            var modalInstance = $uibModal.open({
                //animation: false,
                // templateUrl: '/app/services/map/gdmap.modal.html',
                templateUrl: 'app/services/map/gdmap.modal.html',
                controller: 'GDMapController',
                size: 'md',
                resolve: {
                    point: function () {
                        return data;
                    }
                }
            });

            return modalInstance;
        }

        return service
    }]);

    app.directive('gdMap', function () {
        return {
            restrict: 'EA',
            scope: {
                point: '=?',
            },
            template: '<div></div>',
            replace: true,
            link: function (scope, el, attr, ctrl) {

                scope.map = new AMap.Map(el[0], {
                    resizeEnable: true,
                    zoom: 14,
                })

                if (scope.point.laititude && scope.point.longtitude) {
                    var center = [scope.point.longtitude, scope.point.laititude]
                    scope.map.setCenter(center)
                    scope.marker = new AMap.Marker({ map: scope.map })
                    scope.marker.setPosition(center)
                    scope.$emit('update-center', center)
                }

                scope.map.on('click', function (e) {
                    scope.$emit('map-click', e)

                    if (!scope.marker) {
                        scope.marker = new AMap.Marker({ map: scope.map })
                    }
                    scope.marker.setPosition([e.lnglat.getLng(), e.lnglat.getLat()])
                })

                scope.$on('setCenter', function (event, center) {
                    if (!scope.map) return
                    scope.map.setCenter(center)
                    scope.marker = new AMap.Marker({ map: scope.map })
                    scope.marker.setPosition(center)
                    scope.$emit('update-center', center)
                })
            }
        }
    })

})(window.angular);
