(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .factory('ProfileService', ProfileService);

    ProfileService.$inject = ['$http','DEBUG_INFO_ENABLED'];

    function ProfileService($http,DEBUG_INFO_ENABLED) {

        var dataPromise;

        var service = {
            getProfileInfo : getProfileInfo
        };

        return service;

        function getProfileInfo() {
            if (angular.isUndefined(dataPromise)) {
                dataPromise = $http.get('api/profile-info').then(function(result) {
                    if (result.data.activeProfiles) {
                        var response = {};
                        // console.log(result.data);
                        response.activeProfiles = result.data.activeProfiles;
                        if (DEBUG_INFO_ENABLED){
                            response.ribbonEnv = result.data.ribbonEnv;
                        }else {
                            response.ribbonEnv = null;
                        }
                        response.inProduction = result.data.activeProfiles.indexOf("prod") !== -1;
                        response.swaggerEnabled = result.data.activeProfiles.indexOf("swagger") !== -1;
                        // console.log(response);
                        if (DEBUG_INFO_ENABLED){
                            response.inProduction = false
                        }else {
                            response.inProduction = true
                        }
                        // console.log(response);
                        return response;
                    }
                });
            }
            return dataPromise;
        }
    }
})();
