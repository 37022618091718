(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .factory('TeamOperationSearch', TeamOperationSearch);

    TeamOperationSearch.$inject = ['$resource'];

    function TeamOperationSearch($resource) {
        var resourceUrl =  'api/_search/team-operations/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
