(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('LocationDeleteController',LocationDeleteController);

    LocationDeleteController.$inject = ['$uibModalInstance', 'entity', 'Location', 'Principal'];

    function LocationDeleteController($uibModalInstance, entity, Location, Principal) {
        var vm = this;

        vm.location = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        getAccount();
        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                if (vm.account!==null) {
                    var author = vm.account.authorities;
                    if ($.inArray("ROLE_SUPER", author) !== -1) {
                        vm.auth = "Super";
                    }
                }
            });
        }

        function confirmDelete (id) {
            if (vm.auth=='Super' || vm.location.createBy==vm.account.id){
                if (vm.location.locationTaskIds == null){
                    Location.delete({id: id},
                        function () {
                            $uibModalInstance.close(true);
                        });
                } else if (vm.location.locationTaskIds != null){
                    alert("该坐标已被以下点位引用: " + vm.location.locationTaskIds + "请先解除引用再删除");
                    $uibModalInstance.close(true);
                }
            }else {
                alert("该坐标只能由创建者删除");
                $uibModalInstance.close(true);
            }
        }
    }
})();
