(function(){
    'use strict';

    angular
        .module('taskServiceApp')
        .factory('ParseLinks', ParseLinks);

    function ParseLinks () {

        var service = {
            parse : parse
        };
        function parse(header) {
            if (header!=null&&header.length === 0) {
                throw new Error('input must not be of zero length');
            }

            // Split parts by comma
            if (header!=null){
                var parts = header.split(',');
            }
            var links = {};
            // Parse each part into a named link
            angular.forEach(parts, function(p) {
                var section = p.split(';');
                // if (section.length !== 2) {
                //     throw new Error('section could not be split on ";"');
                // }
                if (section.length == 1){
                    section[1] = ' rel="next"';
                }
                var url = section[0].replace(/<(.*)>/, '$1').trim();
                var queryString = {};
                url.replace(
                    new RegExp('([^?=&]+)(=([^&]*))?', 'g'),
                    function($0, $1, $2, $3) { queryString[$1] = $3; }
                );
                var page = queryString.page;
                if (angular.isString(page)) {
                    page = parseInt(page);
                }
                var name = section[1].replace(/rel="(.*)"/, '$1').trim();
                links[name] = page;
            });
            return links;
        }
        return service;
    }
})();
