(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('ArModelDialogController', ArModelDialogController);

    ArModelDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'ArModel', 'Upload', 'Ahdin'];

    function ArModelDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, ArModel, Upload, Ahdin) {
        var vm = this;

        vm.arModel = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.IMAGE_SERVER = IMAGE_SERVER;
        vm.onFileSelect = onFileSelect;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.arModel.id !== null) {
                ArModel.update(vm.arModel, onSaveSuccess, onSaveError);
            } else {
                ArModel.save(vm.arModel, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('taskServiceApp:arModelUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.createDate = false;
        vm.datePickerOpenStatus.updateDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function onFileSelect (uploadFile,type){

            var uploadModelFile = function(compressedBlob,type) {
                Upload.upload({
                    url: '/api/postfile',
                    fields: {},
                    data: {type:"AR_MODEL"},
                    file: compressedBlob,
                    method: 'POST'
                }).progress(function (evt) {

                    var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                    console.log('progress: ' + progressPercentage + '% ');

                }).success(function (data, status, headers, config) {

                    //update the url
                    if (type === 'android'){
                        vm.arModel.androidContent = data.file;
                    }else {
                        vm.arModel.iosContent = data.file;
                    }

                }).error(function (data, status, headers, config) {

                    console.log('error status: ' + status);
                });
            };
            uploadModelFile(uploadFile,type);
        };

    }
})();
