(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('TaskLocationTeamDeleteController',TaskLocationTeamDeleteController);

    TaskLocationTeamDeleteController.$inject = ['$uibModalInstance', 'entity', 'TaskLocationTeam'];

    function TaskLocationTeamDeleteController($uibModalInstance, entity, TaskLocationTeam) {
        var vm = this;

        vm.taskLocationTeam = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            TaskLocationTeam.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
