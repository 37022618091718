(function() {
    'use strict';
    angular
        .module('taskServiceApp')
        .factory('TeamActivity', TeamActivity);

    TeamActivity.$inject = ['$resource', 'DateUtils'];

    function TeamActivity ($resource, DateUtils) {
        var resourceUrl =  'api/team-activities/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.startTime = DateUtils.convertDateTimeFromServer(data.startTime);
                        data.createDate = DateUtils.convertDateTimeFromServer(data.createDate);
                        data.updateDate = DateUtils.convertDateTimeFromServer(data.updateDate);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();

(function() {
    'use strict';
    angular
        .module('taskServiceApp')
        .factory('TripSummaryActivities', TripSummaryActivities);

    TripSummaryActivities.$inject = ['$resource', 'DateUtils'];

    function TripSummaryActivities ($resource, DateUtils) {
        var resourceUrl =  'api/logic/getTeamActivitiesByTripIdTeamIdForB/:tripId/:teamId';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.startTime = DateUtils.convertDateTimeFromServer(data.startTime);
                        data.createDate = DateUtils.convertDateTimeFromServer(data.createDate);
                        data.updateDate = DateUtils.convertDateTimeFromServer(data.updateDate);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();
