(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('LocationTaskDeleteController',LocationTaskDeleteController);

    LocationTaskDeleteController.$inject = ['$uibModalInstance', 'entity', 'LocationTask'];

    function LocationTaskDeleteController($uibModalInstance, entity, LocationTask) {
        var vm = this;

        vm.locationTask = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        vm.error = null;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete(id) {
            if (confirm("该点位下的任务将被全部删除，是否继续？")){
                confirmDeletes(id);
            }else {
                $uibModalInstance.close(true);
            }
        }
        function confirmDeletes (id) {
            LocationTask.delete({id: id},
                function (res) {
                    // console.log(res);
                    if (res!=null && res.errorCode==-1){
                        vm.error = res.message;
                        return;
                    }
                    $uibModalInstance.close(true);
                },function (err) {
                    console.log(err);
                });
        }
    }
})();
