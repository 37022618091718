(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('EAccountMyPayWaitController', EAccountMyPayWaitController);

    EAccountMyPayWaitController.$inject = ['$state'];

    function EAccountMyPayWaitController($state) {
        var vm = this;

        vm.back = back;
        function back() {
            $state.go("e-account-my");
        }

    }
})();
