(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('ArModelDetailController', ArModelDetailController);

    ArModelDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'ArModel'];

    function ArModelDetailController($scope, $rootScope, $stateParams, previousState, entity, ArModel) {
        var vm = this;

        vm.arModel = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('taskServiceApp:arModelUpdate', function(event, result) {
            vm.arModel = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
