(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('TeamLocationProgressDialogController', TeamLocationProgressDialogController);

    TeamLocationProgressDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'TeamLocationProgress'];

    function TeamLocationProgressDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, TeamLocationProgress) {
        var vm = this;

        vm.teamLocationProgress = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.teamLocationProgress.id !== null) {
                TeamLocationProgress.update(vm.teamLocationProgress, onSaveSuccess, onSaveError);
            } else {
                TeamLocationProgress.save(vm.teamLocationProgress, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('taskServiceApp:teamLocationProgressUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.createDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
