(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('ReportDetailController', ReportDetailController);

    ReportDetailController.$inject = ['$scope', '$rootScope','$timeout', '$state','pagingParams','paginationConstants','ParseLinks','AlertService', 'previousState', 'entity', 'EAccount', 'User','UserSearch','$http','ReportRemittance'];

    function ReportDetailController($scope, $rootScope,$timeout, $state,pagingParams,paginationConstants,ParseLinks,AlertService, previousState, entity, EAccount, User,UserSearch,$http,ReportRemittance) {
        var vm = this;
        vm.report = entity;
        //获取企业id
        vm.id = $state.params.id;
        vm.previousState = previousState.name;
        vm.itemsPerPage = 250;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.loadPage = loadPage;
        vm.clear = clear;
        vm.transition = transition;

        //时间控件
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });
        vm.datePickerOpenStatus.create_date = false;
        vm.datePickerOpenStatus.update_date = false;
        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

        vm.query = loadRemittance;
        if (vm.report.startTime==undefined||vm.report.endTime==undefined){
            vm.report.startTime = "";
            vm.report.endTime = "";
            loadRemittance();
        }
        // console.log(vm.report);
        function loadRemittance() {
            // ReportRemittance.getRemittance({
            //     page: pagingParams.page - 1,
            //     size: vm.itemsPerPage,
            //     sort : sort(),
            //     data:{startTime:start,endTime:end}
            // },onSuccess,onError);
            if ((vm.report.startTime!=null&&vm.report.startTime!="")&&(vm.report.endTime!=null&&vm.report.endTime!="")){
                vm.report.startTime = Todate(vm.report.startTime);
                vm.report.endTime = Todate(vm.report.endTime);
            }
            $http({
                method: 'GET',
                url: '/api/report/findDetailToCompany?eAccountId='+vm.id+'&&startTime='+vm.report.startTime+'&&endTime='+vm.report.endTime,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort : sort()
            }).then(function (res,headers) {
                // vm.links = ParseLinks.parse(headers('link'));
                // vm.totalItems = headers('X-Total-Count');
                vm.totalItems = res.data.length;
                vm.queryCount = vm.totalItems;
                vm.reportDetail = res.data;
                // console.log(vm.reportDetail);
                vm.page = pagingParams.page;
            },function (err) {
                console.log(err);
            })
        }
        function sort() {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            if (vm.predicate !== 'id') {
                result.push('id');
            }
            return result;
        }
        function onSuccess(data, headers) {
            vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = headers('X-Total-Count');
            vm.queryCount = vm.totalItems;
            vm.records = data;
            console.log(vm.records);
            vm.page = pagingParams.page;
        }
        function onError(error) {
            AlertService.error(error.data.message);
            console.log(error);
        }
        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }
        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }
        function search(searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }
        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }

        function Todate(num) {
            var date = new Date(num);
            var a = [date.getFullYear(), date.getMonth() + 1, date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()];
            for(var i = 0, len = a.length; i < len; i ++) {
                if(a[i] < 10) {
                    a[i] = '0' + a[i];
                }
            }
            date = a[0] + '-' + a[1] + '-' + a[2] + 'T' + a[3] + ':' + a[4] + ':' + a[5] + 'Z[UTC]';
            return date;
        }

        var unsubscribe = $rootScope.$on('taskServiceApp:eAccountUpdate', function(event, result) {
            vm.eAccount = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
