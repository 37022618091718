(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .factory('TeamLocationProgressSearch', TeamLocationProgressSearch);

    TeamLocationProgressSearch.$inject = ['$resource'];

    function TeamLocationProgressSearch($resource) {
        var resourceUrl =  'api/_search/team-location-progresses/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
