(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .factory('BaseTaskCountDownSearch', BaseTaskCountDownSearch);

    BaseTaskCountDownSearch.$inject = ['$resource'];

    function BaseTaskCountDownSearch($resource) {
        var resourceUrl =  'api/_search/base-task-count-downs/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
