(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('EquipFromTaskDialogController', EquipFromTaskDialogController);

    EquipFromTaskDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'EquipFromTask','Principal'];

    function EquipFromTaskDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, EquipFromTask,Principal) {
        var vm = this;

        vm.equipFromTask = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        Principal.identity().then(function(account) {
            vm.account = account;
        });

        function save () {
            vm.isSaving = true;

            var date = new Date();

            if (vm.equipFromTask.id !== null) {
                vm.equipFromTask.updateDate = date;
                vm.equipFromTask.updateBy = vm.account.firstName;
                EquipFromTask.update(vm.equipFromTask, onSaveSuccess, onSaveError);
            } else {
                vm.equipFromTask.createDate = date;
                vm.equipFromTask.createBy = vm.account.firstName;
                EquipFromTask.save(vm.equipFromTask, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('taskServiceApp:equipFromTaskUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.createDate = false;
        vm.datePickerOpenStatus.updateDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
