/**
 * Created by snowzhang on 2017/3/23.
 */
(function() {
    'use strict';
    angular
        .module('taskServiceApp')
        .controller('BaseTaskController_Trip', BaseTaskController_Trip);

    BaseTaskController_Trip.$inject = ['$scope', '$state', 'BaseTask','ParseLinks', 'pagingParams', 'paginationConstants','$http','$stateParams'];

    function BaseTaskController_Trip ($scope, $state, BaseTask, ParseLinks, pagingParams, paginationConstants,$http,$stateParams) {
        var vm = this;
        vm.IMAGE_SERVER = IMAGE_SERVER;
        var id = $stateParams.id;
        getBaseTaskByTripId(id);
        function getBaseTaskByTripId(id) {
            $http({
                method: 'GET',
                url: '/api/base-tasks/locationTask/'+id
            }).then(function successCallback(response) {
                // console.log(response.data);
                vm.baseTasks=response.data;
            }, function errorCallback(response) {
                // 请求失败执行代码
            });
        }
    }
})();
