(function() {
    'use strict';

    angular
        .module('taskServiceApp')
        .controller('EAccountMyPayErrorController', EAccountMyPayErrorController);

    EAccountMyPayErrorController.$inject = ['$state'];

    function EAccountMyPayErrorController($state) {
        var vm = this;

        vm.back = back;
        function back() {
            $state.go("e-account-my-purchase");
        }

    }
})();
